import React from "react"
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import PrivacyPolicyContent from '../components/PrivacyPolicyContent/index';

function PrivacyPolicy({ location }) {
    return(
        <div>
            <Helmet>
                <title>Voa Labs | Privacy Policy</title>
                <meta name="description" content="Voa Labs strategizes, designs & builds digital experiences. We are a digital product studio from Switzerland." />
            </Helmet>
            <PrivacyPolicyContent lang="de" location={location} />
        </div>
    )
}

PrivacyPolicy.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object,
  };

export default PrivacyPolicy;